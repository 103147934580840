exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cart-index-js": () => import("./../../../src/pages/cart/index.js" /* webpackChunkName: "component---src-pages-cart-index-js" */),
  "component---src-pages-cart-payment-js": () => import("./../../../src/pages/cart/payment.js" /* webpackChunkName: "component---src-pages-cart-payment-js" */),
  "component---src-pages-cart-shipping-js": () => import("./../../../src/pages/cart/shipping.js" /* webpackChunkName: "component---src-pages-cart-shipping-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-store-index-js": () => import("./../../../src/pages/store/index.js" /* webpackChunkName: "component---src-pages-store-index-js" */),
  "component---src-pages-store-product-id-js": () => import("./../../../src/pages/store/[productId].js" /* webpackChunkName: "component---src-pages-store-product-id-js" */)
}

